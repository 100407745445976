<template>
  <b-card>
    <ValidationProvider
        v-if="!isUpdate"
        v-slot="{ errors }"
        name="ยูสเซอร์เนม"
        :rules="isUpdate ? null : 'required'"
    >
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.username')} *`"
          label-for="username"
      >
        <b-form-input
            id="username"
            v-model="form.username"
            placeholder="@username"
            :state="errors[0] ? false : null"
        ></b-form-input>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
        v-slot="{ errors }"
        name="Agent Prefix"
        rules="required"
    >
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`AgentPrefix *`"
          label-for="prefix"
      >
        <b-form-input
            id="prefix"
            v-model="form.prefix"
            placeholder="@prefix"
            :state="errors[0] ? false : null"
            :readonly="isAgent"
        ></b-form-input>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
        v-if="!isUpdate"
        v-slot="{ errors }"
        :name="$t('user.password')"
        :rules="isUpdate ? null : 'required'"
    >
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.password')} *`"
          label-for="agent_password"
      >
        <b-input-group>
          <b-form-input
              id="agent_password"
              v-model="form.password"
              :type="isShowPassword ? 'text' : 'password'"
              :state="errors[0] ? false : null"
              autocomplete="off"
          ></b-form-input>
          <template #append>
            <b-button @click="isShowPassword = !isShowPassword">
              <i
                  v-if="isShowPassword"
                  class="uil uil-eye"
              ></i>
              <i
                  v-else
                  class="uil uil-eye-slash"
              ></i>
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>
    <ValidationProvider
        v-slot="{ errors }"
        name="name"
        rules="required"
    >
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('agent.name')} *`"
          label-for="name"
      >
        <b-form-input
            id="name"
            v-model="form.name"
            :state="errors[0] ? false : null"
            :readonly="isAgent"
        ></b-form-input>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
        v-if="!isUpdate"
        v-slot="{ errors }"
        name="phoneNumber"
        :rules="
                  isUpdate
                    ? null
                    : {
                        required: true,
                        min: 10,
                        regex: /^[0-9]+$/,
                      }
                "
    >
      <b-form-group
          :label="`${$t('user.phone')} *`"
          label-for="phoneNumber"
          label-cols-sm="12"
          label-cols-lg="3"
      >
        <b-form-input
            id="phoneNumber"
            v-model="form.phoneNumber"
            v-mask="'##########'"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            aria-describedby="input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>

    <ValidationProvider
        v-slot="{ errors }"
        name="registrationUrl"
        rules="required"
    >
      <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('agent.register_url')} *`"
          label-for="registrationUrl"
      >
        <b-input-group>
          <b-form-input
              id="registrationUrl"
              v-model="form.registrationUrl"
              :state="errors[0] ? false : null"
              placeholder="ใส่แค่ Domain เท่านั้น ( ตัวอย่างเช่น - example.com )"
              :readonly="isAgent"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | ข้อมูลทั่วไป',
  },
  name: 'General',
  props: {
    isUpdate: {
      type: Boolean
    },
    agentData: {
      type: Object,
      default: () => ({
        username: '',
        password: '',
        prefix: '',
        name: '',
        phoneNumber: '',
        registrationUrl: ''
      })
    }
  },
  data() {
    return {
      form: {},
      isShowPassword: false
    }
  },
  computed: {
    ...mapGetters(['isAgent'])
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      username: this.agentData.username,
      password: this.agentData.password,
      prefix: this.agentData.prefix,
      name: this.agentData.name,
      phoneNumber: this.agentData.phoneNumber,
      registrationUrl: this.agentData.registrationUrl
    }
  }
}
</script>