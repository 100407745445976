var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(!_vm.isUpdate)?_c('ValidationProvider',{attrs:{"name":"ยูสเซอร์เนม","rules":_vm.isUpdate ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('user.username')) + " *"),"label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username","placeholder":"@username","state":errors[0] ? false : null},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)]}}],null,false,2943825779)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Agent Prefix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"AgentPrefix *","label-for":"prefix"}},[_c('b-form-input',{attrs:{"id":"prefix","placeholder":"@prefix","state":errors[0] ? false : null,"readonly":_vm.isAgent},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}})],1)]}}])}),(!_vm.isUpdate)?_c('ValidationProvider',{attrs:{"name":_vm.$t('user.password'),"rules":_vm.isUpdate ? null : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('user.password')) + " *"),"label-for":"agent_password"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{on:{"click":function($event){_vm.isShowPassword = !_vm.isShowPassword}}},[(_vm.isShowPassword)?_c('i',{staticClass:"uil uil-eye"}):_c('i',{staticClass:"uil uil-eye-slash"})])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"agent_password","type":_vm.isShowPassword ? 'text' : 'password',"state":errors[0] ? false : null,"autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)]}}],null,false,3165440672)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('agent.name')) + " *"),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"readonly":_vm.isAgent},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])}),(!_vm.isUpdate)?_c('ValidationProvider',{attrs:{"name":"phoneNumber","rules":_vm.isUpdate
                  ? null
                  : {
                      required: true,
                      min: 10,
                      regex: /^[0-9]+$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('user.phone')) + " *"),"label-for":"phoneNumber","label-cols-sm":"12","label-cols-lg":"3"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"id":"phoneNumber","state":errors[0] ? false : null,"aria-invalid":false,"aria-describedby":"input-live-feedback"},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1497056567)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"registrationUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('agent.register_url')) + " *"),"label-for":"registrationUrl"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"registrationUrl","state":errors[0] ? false : null,"placeholder":"ใส่แค่ Domain เท่านั้น ( ตัวอย่างเช่น - example.com )","readonly":_vm.isAgent},model:{value:(_vm.form.registrationUrl),callback:function ($$v) {_vm.$set(_vm.form, "registrationUrl", $$v)},expression:"form.registrationUrl"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }